<template>
    <section class="live-video live-video-2">
        <ul class="live-video__list">
            <li  class="live-video__items" :class="'live-video__items-' + autoClass" v-for="cam in cams" :key="cam.id">
                <div class="live-video__live-left">
                    <p>live Video</p>
                    <p>{{cam.name}}</p>
                </div>
                <div class="live-video__container">
                    <video id="'live-video-'+cam.id"
                           controls
                           x-autoplay
                           width="100%"
                           height="100%"
                           :src="cam.url || ''"
                           :data-url="cam.url || ''"
                           :data-timerestriction="timeRestriction * 60"
                    />
                </div>
                <!-- <UnitsList_unit_status_icon :status="status" :position="'tr-20'"/> -->
                <div class="live-video__live live-button" @click="cam.view = !cam.view"></div>
                <!-- <div class="live-video__play"><img src="@/assets/image/video-play.png" alt=""></div> -->
            </li>
        </ul>
    </section>
</template>

<script>
// import UnitsList_unit_status_icon from "@/components/units/UnitsList_unit_status_icon.vue";
export default {
    name: "LiveVideo",
    components: {
        // UnitsList_unit_status_icon
    },
    props:{
        'value': [Array, Object],
        'status': {
            type: String,
            default: () => { return '' }
        }
    },
    data() {
        return {
            timeRestriction: 1,
        }
    },
    computed:{
        videos(){
            return this.value
        },
        cams(){
            return this.videos.filter(v => v.view)
        },
        autoClass(){
            let classes = ''
            let length = this.cams.length
            if(length < 3){
                classes = 'xr2'
            }else
            if(length > 3 && length < 5){
                classes = 'r2xc4'
            } else
            if(length > 4 && length < 7){
                classes = 'r3xc2'
            }else
            if(length > 6){
                classes = 'r3xc3'
            }
            return classes
        },
    },
    methods:{
        setShowALL(){
            this.cams.forEach(e => e.view = true)
        },
    }
}
</script>

<style scoped>

</style>